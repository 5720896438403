import map from 'lodash/map';
import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { setError } from '@/store/setError';
import { portalHost } from '../../../const/host.const';

const { postAlertTrigger, getAlertTriggerData } = carRental.alert;

const { getStationsList } = carRental.station;

export const scopes = {
  alertTriggerData: 'alertTriggerData',
  newAlertTrigger: 'newAlertTrigger',
  stations: 'stations',
};

const newAlertTrigger = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      mutations: {
        setError,
      },
      actions: {
        async postAlertTrigger({ rootState, commit }, data) {
          return runAsyncFlow(commit, {
            request: postAlertTrigger,
            params: [data, portalHost(rootState)],
            scope: scopes.newAlertTrigger,
          });
        },
        async getAlertTriggerData({ rootState, commit }, triggerUuid) {
          await runAsyncFlow(commit, {
            request: getAlertTriggerData,
            params: [triggerUuid, portalHost(rootState)],
            scope: scopes.alertTriggerData,
          });
        },
        getStationsList({ rootState, commit }, { query = {} }) {
          runAsyncFlow(commit, {
            request: getStationsList,
            params: [query, portalHost(rootState)],
            scope: scopes.stations,
          });
        },
      },
      getters: {
        getStations(state) {
          return map(state[scopes.stations].data, ({ greenwayId, greenwayName }) => ({
            value: greenwayId,
            label: `${greenwayId} - ${greenwayName}`,
          }));
        },
      },
    }
  )));

export default newAlertTrigger;
